import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    students: [],
    student: {},
    count: null,
    totalItems: 0,
  },
  mutations: {
    index(state, payload) {
      state.students = payload
    },
    indexPaginated(state, payload) {
        if (Array.isArray(payload.data)) {
            state.students = payload.data
            state.totalItems = payload.total
            return
        }
        state.students = Object.keys(payload.data).map((key) => payload.data[key])
        state.totalItems = payload.total
    },
    count(state, payload) {
      state.count = payload
    },
    show(state, payload) {
      // payload.all_doyangs = state.student.all_doyangs ? state.student.all_doyangs : payload.all_doyangs
      state.student = payload
    },
    delete(state, payload) {
      const students = state.students.filter( student => payload.id != student.id)
      state.students = students
    },
    assignResult(state, payload) {
        console.log('payload', payload)
        state.students = state.students.map((student) => {
            if (student.id == payload.user_id) {
                student.revision_results = payload
                console.log('student', student)
            }
            return student
        })
    }
  },
  actions: {
    verifyForeignersCsv({commit}, payload) {
      // payload is a form data
      return axios.post(`${ENV.API_URL}/api/users/verify-foreigners-csv`, 
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
        return res.data
      })
    },
    uploadForeignersCsv({commit}, payload) {
      // payload is a form data
      return axios.post(`${ENV.API_URL}/api/users/upload-foreigners-csv`, 
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
        return res.data
      })
    },
    downloadCurriculumCsv({ commit }, payload) {
      console.log(commit);
      return axios.get(`${ENV.API_URL}/api/users/${payload}/curriuculum-csv`).then((res) => {
        return res.data
      })
    },
    count({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/users?count=1&${payload}`).then((res) => {
        commit('count', res.data)
      })
    },
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/users${payload ? payload : ''}`).then((res) => {
        if (payload.includes('paginate=1')) {
          commit('indexPaginated', res.data)
        }
        else
          commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/users/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
        if (payload instanceof FormData) {
            console.log('payload', payload);
            console.log('payload', payload.get('id'));
            console.log('payload', payload.get('is_rejected_insurance_file'));
            payload.append('_method', 'PUT');
            return axios.post(`${ENV.API_URL}/api/users/${payload.get('id')}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                contentType: false,
                processData: false,
            }).then((res) => {
                commit('show', res.data)
            })
        }
        return axios.post(`${ENV.API_URL}/api/users`, payload).then((res) => {
            commit('show', res.data)
            return res.data
        })
    },
    update({ commit }, payload) {
        if (payload instanceof FormData) {
            console.log('payload', payload);
            console.log('payload', payload.get('id'));
            console.log('payload', payload.get('is_rejected_insurance_file'));
            payload.append('_method', 'PUT');
            return axios.post(`${ENV.API_URL}/api/users/${payload.get('id')}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                commit('show', res.data)
            })
        }
        return axios.put(`${ENV.API_URL}/api/users/${payload.id}`, payload).then((res) => {
            commit('show', res.data)
        })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/users/${payload}`).then((res) => {
        commit('delete', res.data)
      })
    }
  },
  getters: {
    students: state => {
      return state.students
    },
    student: state => {
      return state.student
    },
    count: state => state.count,
    totalItems: state => {
      return state.totalItems
    }
  },
  modules: {
  }
}
