<template>
  <div>
    <v-progress-circular :size="200" :width="10" v-if="loading" color="green lighten-2" indeterminate />
    <v-container v-else>
      <v-row>
        <v-col>
          <h2>INGRESOS</h2>
          <v-btn color="blue lighten-2" @click="prepareToPrint(false)" dark>Imprimir</v-btn>
          <v-btn color="blue lighten-2" @click="prepareToPrint(true)" dark>Imprimir colapsado</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DMYDateField v-model="minDate" label="Desde fecha"></DMYDateField>
        </v-col>
        <v-col>
          <DMYDateField v-model="maxDate" label="Hasta fecha"></DMYDateField>
        </v-col>
        <v-col>
          <v-checkbox v-model="onlyDevolutions" label="Solo devoluciones"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-model="onlyIns" label="Solo ingresos"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-model="onlyOuts" label="Solo egresos"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CapsVTextField outlined v-model="searchProduct" :disabled="tableLoading" placeholder="Producto">
          </CapsVTextField>
        </v-col>
        <v-col>
          <CapsVTextField outlined :disabled="tableLoading" v-model="searchStudent" placeholder="Cliente">
          </CapsVTextField>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols=12 class="text-center">
            <h5>Total de Crédito: ${{ floatToCurrency(this.totalCredit) }}</h5>
            <h5>Total de Débito: ${{ floatToCurrency(this.totalDebit) }}</h5>
            <h5>Total de Depóstios: ${{ floatToCurrency(this.totalDeposit) }}</h5>
            <h5>Total de Efectivo: ${{ floatToCurrency(this.totalCash) }}</h5>
            <h5>Total: ${{ floatToCurrency(this.total) }}</h5>
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col v-if="items">
          <v-data-table :loading="tableLoading" id="print-table" :server-items-length="totalItems" :headers="headers"
            :items="items" :sort-by="['created_at']" :sort-desc="[true]" :items-per-page="15"
            @update:page="(pageNum) => { this.pageNum = pageNum; sendPaginationOptions() }"
            @update:sort-by="(column) => { this.sortBy = column; sendPaginationOptions() }"
            @update:sort-desc="(boolean) => { this.sortDesc = boolean; sendPaginationOptions() }">
            <template v-slot:item="{ item }">
              <tr :class="item.paid_date ? '' : 'red lighten-4'">
                <td class="text-left" v-for="(header, index) in headers" :key="item.id + index">
                  {{ item[header.value] }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row ref="row-print" id="row-print" v-if="printing">
        <v-col cols=12 class="text-center">
          <h3>CONTABILIDAD</h3>
          <h5>DESDE {{ minDate ? minDate : "EL INICIO" }} HASTA {{ maxDate ? maxDate : 'HOY' }}</h5>
        </v-col>
        <v-col cols=12>
          <div class="table-responsive">
            <v-data-table id="print-table" :sort-by="['created_at']" :sort-desc="[true]"
              :items="groupedItems && groupedItems.length > 0 ? groupedItems : items" :totalItems="totalItems"
              :items-per-page="20" :headers="headers" disable-pagination hide-default-footer :server-items-length="20"
              :tableLoading="tableLoading"
              @update:page="(pageNum) => { this.pageNum = pageNum; sendPaginationOptions() }"
              @update:sort-by="(column) => { this.sortBy = column; sendPaginationOptions() }"
              @update:sort-desc="(boolean) => { this.sortDesc = boolean; sendPaginationOptions() }">
              <template v-slot:item="{ item }">
                <tr :class="item.paid_date ? '' : 'red lighten-4'">
                  <td class="text-left" v-for="(header, index) in headers" :key="item.id + index">
                    {{ item[header.value] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ENV from "../../../env";
import DMYDateField from "../globals/DMYDateField.vue";
var debounce = require('debounce');

export default {
  watch: {
    minDate: function () {
      this.sendPaginationOptions();
    },
    maxDate: function () {
      this.sendPaginationOptions();
    },
    searchProduct: debounce(function (searchProduct) {
      this.sendPaginationOptions({ searchProduct });
    }, 500),
    searchStudent: debounce(function (searchStudent) {
      this.sendPaginationOptions({ searchStudent });
    }, 500),
  },
  mounted() {
    this.authInfo = this.$store.getters["Auth/authInfo"];
    this.payload = `for_contability=1&orderBy[id]=desc&where[doyang_id]=${this.paymentsPayload}&where[client_doyang_id]=${this.paymentsPayload}`
    this.$store.dispatch(
      "Payment/index",
      `?paginate=1&${this.payload}`
    ).then(() => {
      this.loading = false
      this.tableLoading = false
    });
    this.$store.dispatch(
      "Payment/index",
      `?paginate=1&amounts_only=1&${this.payload}`
    ).then((res) => {
      this.total = parseFloat(res.total || 0)
      this.totalCredit = parseFloat(res.creditCardAmount || 0)
      this.totalDebit = parseFloat(res.debitCardAmount || 0)
      this.totalDeposit = parseFloat(res.depositAmount || 0)
      this.totalCash = parseFloat(res.cashAmount || 0)
    });
    if (
      !localStorage.getItem("selectedDoyang") &&
      !(
        this.authInfo &&
        this.authInfo.user &&
        ENV.permissions.can_see_general_direction.includes(
          this.authInfo.user.role_id
        )
      )
    ) {
      this.$router.push({ name: "Home" });
    }
  },
  props: {
    doyangId: Number,
  },
  components: {
    DMYDateField,
  },
  computed: {
    totalItems() {
      return this.$store.getters["Payment/totalItems"]
    },
    items() {
      let paymentsGetter = this.$store.getters["Payment/payments"]
      if (!paymentsGetter) return []
      const paymentsArray = Array.isArray(paymentsGetter) ? paymentsGetter : Object.values(paymentsGetter)
      let payments = JSON.parse(
        JSON.stringify(
          paymentsArray
        )
      ).map((payment) => {
        payment.amount =
          (payment.devolution != 0 && payment.amount > 0) ||
            (payment.payment_concept_id == 9 && payment.client_doyang_id)
            ? payment.amount * -1
            : payment.amount;
        payment.parsedAmount =
          parseInt(payment.amount) > 0
            ? `$${payment.amount}`
            : `-$${Math.abs(payment.amount)}`;
        payment.date = payment.created_at.substring(0, 10);
        payment.parsedDate = this.parseDate(payment.date);
        return payment;
      });
      payments = this.onlyDevolutions
        ? payments.filter((payment) => payment.devolution)
        : payments;
      payments = this.onlyOuts
        ? payments.filter((payment) => payment.amount < 0)
        : payments;
      payments = this.minDate
        ? payments.filter((payment) => payment.date >= this.minDate)
        : payments;
      payments = this.maxDate
        ? payments.filter((payment) => payment.date <= this.maxDate)
        : payments;
      return payments;
    },
    selectedDoyang() {
      return this.$store.getters["Auth/selectedDoyang"];
    },
    paymentsPayload() {
      return localStorage.getItem("selectedDoyang")
        ? JSON.parse(localStorage.getItem("selectedDoyang")).id
        : "";
    },
  },
  methods: {
    floatToCurrency(value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sendPaginationOptions: debounce(function ({ searchProduct, searchStudent } = {}) {
      this.tableLoading = true
      this.searchProduct = searchProduct ?? this.searchProduct
      this.searchStudent = searchStudent ?? this.searchStudent
      let startDateString = this.minDate ? `&start_date=${this.minDate}` : ''
      let endDateString = this.maxDate ? `&end_date=${this.maxDate}` : ''
      const stringFilter = `?${this.payload}&paginate=1&page=${this.pageNum}` +
        `${this.searchProduct ? '&whereAccessor[title]=' + this.searchProduct : ''}` +
        `${this.searchStudent ? '&whereAccessor[client_name]=' + this.searchStudent : ''}` +
        `${startDateString}${endDateString}`
      this.$store.dispatch('Payment/index', stringFilter).then(() => this.tableLoading = false)
      this.$store.dispatch('Payment/index', stringFilter + '&amounts_only=1').then((res) => {
        this.total = parseFloat(res.total || 0)
        this.totalCredit = parseFloat(res.creditCardAmount || 0)
        console.log('this.totalCredit', this.totalCredit)
        this.totalDebit = parseFloat(res.debitCardAmount || 0)
        this.totalDeposit = parseFloat(res.depositAmount || 0)
        this.totalCash = parseFloat(res.cashAmount || 0)
      })
    }, 500),
    group(items, key) {
      return items.reduce(
        (result, item) => ({
          ...result,
          [item[key]]: [
            ...(result[item[key]] || []),
            item,
          ],
        }),
        {},
      )
    },
    prepareToPrint(collapsed = false) {
      const minDate = this.minDate
      const maxDate = this.maxDate
      const onlyIns = this.onlyIns
      const onlyOuts = this.onlyOuts
      this.$store.dispatch("Payment/index", `/download_contability?ins_only=${onlyIns}&outs_only=${onlyOuts}&doyang_id=${this.selectedDoyang.id}&start_date=${minDate}&end_date=${maxDate}&collapsed=${collapsed}`).then((res) => {
        this.downloadStringInNewPage(res)
      });
    },
    downloadStringInNewPage(string, filename) {
      const blob = new Blob([string], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    prepareToPrintCollapsed() {
      const objectItems = this.group(this.items, "title")
      this.groupedItems = Object.keys(objectItems).map(key => {
        return {
          parsedDate: "",
          qty: objectItems[key].length,
          title: key,
          client_name: "",
          concept_type: "-",
          parsedAmount: `$${objectItems[key].reduce((carry, item) => carry += parseFloat(item.amount), 0)}`
        }
      })
      this.printing = true
      setTimeout(this._printTable, 2000)
    },
    _printTable() {
      console.log('printing')
      // Get HTML to print from element
      const prtHtml = document.getElementById('row-print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      this.printing = false
      this.groupedItems = []
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${ENV.months[
        parseInt(month)
      ].abv.toUpperCase()}/${year}`;
    },
  },
  data() {
    return {
      total: 0,
      totalCredit: 0,
      totalDebit: 0,
      totalDeposit: 0,
      totalCash: 0,
      onlyIns: false,
      pageNum: 1,
      column: 1,
      boolean: 1,
      loading: true,
      tableLoading: true,
      printing: false,
      searchProduct: "",
      searchStudent: "",
      onlyDevolutions: false,
      onlyOuts: false,
      minDate: null,
      maxDate: null,
      groupedItems: [],
      search: "",
      headers: [
        { text: "id", value: "id" },
        { text: "Fecha", value: "parsed_created_at" },
        { text: "Cantidad", value: "qty" },
        { text: "Artículo", value: "title" },
        { text: "Cliente", value: "client_name" },
        { text: "Concepto", value: "concept_type" },
        { text: "Monto", value: "parsedAmount" },
      ],
      storeModel: "Payment",
      itemsPerPage: 20,
    };
  },
};
</script>

<style></style>
